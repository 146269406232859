<template>
  <div class="supplierManagement" v-loading="loading">
    <div class="globle_border content">
      <header>
        <el-date-picker v-model="timeValue" type="date" placeholder="请选择结算日期" @change="onRevisionDate"
          clearable></el-date-picker>
        <el-input placeholder="请输入缴费单号" suffix-icon="el-icon-search" v-model="feeCode" :clearable="true"
          @change="onCurrentPage()"></el-input>
        <el-input placeholder="请输入商品条形码" suffix-icon="el-icon-search" v-model="barCode" :clearable="true"
          @change="onCurrentPage()"></el-input>
      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData" style="width: 100%">
          <el-table-column label="序号" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
              <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <el-table-column prop="feeCode" label="缴费单号" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="createdTime" label="结算时间" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="" label="结算周期" show-overflow-tooltip min-width="200" align="center">
            <template slot-scope="scope">
              {{ `${scope.row.startDate}-${scope.row.endDate}` }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="productCode" label="商品编号" show-overflow-tooltip min-width="120" align="center">
          </el-table-column> -->
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="categoryName" label="商品类目" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="settlementAmount" label="结算金额(元)" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="feeRate" label="费率" show-overflow-tooltip min-width="80" align="center">
            <template slot-scope="scope">
              {{ `${scope.row.feeRate} %` }}
            </template>
          </el-table-column>
          <el-table-column prop="feeAmount" label="技术服务费" header-align="center" fixed="right" min-width="100"
            align="center">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      // // 表格数据
      tableData: [],
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      timeValue: "", //选择日期
      feeCode: "", //缴费单号
      barCode: "", //条形码
    }
  },
  created() {
    this.getRequest()
  },
  methods: {
    // 请求列表
    getRequest() {
      this.loading = true
      this.$axios
        .get(this.$api.techDetailInfo, {
          params: {
            bizId: this.$store.state.userInfo.supplierId, //供应商id
            bizType: 2, //类型：1商超；2供应商
            startDate: this.timeValue || null, //选择时间
            endDate: this.timeValue || null, //选择时间
            feeCode: this.feeCode || null, //缴费编号
            barCode: this.barCode || null, //商品编号
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          const { code, result } = res.data
          if (code == 100) {
            this.totalCount = result.totalCount
            this.tableData = result.list
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 日期搜索
    onRevisionDate(date) {
      if (date == null) {
        this.timeValue = ""
      } else {
        let formattedDate = new Date(new Date(date).getTime() + 8 * 60 * 60 * 1000).toISOString().split("T")[0]
        this.timeValue = formattedDate
      }
      this.onCurrentPage()
    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val
      this.tableData = []
      this.getRequest()
    },
  },
}
</script>

<style scoped lang="scss">
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;
    }

    header {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .el-input,
      .el-select {
        width: 180px;
        margin-left: 10px;
        margin-bottom: 20px;
      }

      .el-button {
        margin-left: 10px;
        margin-bottom: 20px;
      }

      .el-date-editor {
        margin-left: 10px;
        width: 180px;
      }

      /deep/ .el-date-editor {
        background-color: #f2f2f2;
        border: none;
        border-radius: 10px;
      }

      /deep/.el-range-input {
        background-color: #f2f2f2;
      }

    }
  }

  .tableBox {
    overflow: auto;

    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: "";
      position: absolute;
      background-color: #ebeef5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }
  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }
}
</style>
